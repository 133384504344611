import React from "react";
import logoDark from '../../assets/img/logo/RD-logo.svg';

const ThemeSuspense = () => {
  return (
    // <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
    //   Loading...
    // </div>
    <div className="w-full h-screen flex items-center justify-center">
      <img src={logoDark} alt="dashtar" className="max-w-full max-h-full" />
    </div>
  );
};

export default ThemeSuspense;
